<template>
    <div class="inform-page">
        <div class="infrom-main" @click='goback'>
                <div class='time-r'>婚姻状况</div>
                <div class='time-l'>
                    <input class='loan-input'  readonly='readonly' v-model="infoValue" placeholder="请选择"  @change="changebanks($event)"/>
                    <span class='infrom-icon'></span>
                </div>

        </div>
        <div class="infrom-main">
                <div class='time-r'>房产状况</div>
                    <div class='time-l'>
                        <input class='loan-input'   placeholder="请输入房产状况"  @change="changebank($event)"/>         
                    </div> 
        </div>
       <div class="inform-btn" @click="confirmBtn">确定</div>
       <!-- 弹框 -->
        <Modal :show="showback" @cancel="cancel" :showCancel="true" class='infrom-modal' :title="'选择婚姻状况'">
            <div class="infrom-modal-main" v-for="item in columns" :key='item' @click="modalCheck(item)">
                <div class="infrom-moadl-line"  >{{item}}</div>
                <!-- <div  class="infrom-moadl-line">已婚</div> -->
            </div>
        </Modal>
    </div>
</template>
<style lang="less" src="./index.less" scoped></style>
<script>
import { onMounted, toRefs,reactive } from "vue"
import APP from '../../utils/APP'
import API from '../../server/api'
import {Toast} from 'vant';
import Modal from '@/components/Modal/Modal.vue'
import BankList from '@/components/BankList/index.vue'
export default ({
    components: {
   
        Modal
    },
    setup() {
        const state=reactive({
            infoValue: '',
            showback: false,
            columns: ['未婚', '已婚'],
            valueHome:''
        })
        const onConfirm=(value)=>{
            state.infoValue=value,
            state.showPicker=false
        }
        const goback = () => {
            state.showback = true
            
        }
        const cancel = (show) => {
            state.showDialog = show
            state.showback = show
            state.showUse = show
    
        }
        const modalCheck=(e)=>{
            console.log(e,99);
            state.infoValue=e
            state.showback = false
        }
        //获取输入房产值
        const changebank=(e)=>{
            console.log(e.target.value);
            state.valueHome=e.target.value
        }
        //确认提交
        const confirmBtn=()=>{
            if(state.infoValue==''){
               Toast('请选择婚姻状况')
            }
            if(state.valueHome==''){
                Toast('请填写房产状况')
            }
            // console.log(state.valueHome,state.infoValue);
        }
        return{
            ...toRefs(state),
           goback,
           cancel,
           changebank,
           modalCheck,
           confirmBtn
        }
    },
})
</script>